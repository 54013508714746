import React from 'react'
import Header from '../components/Header/Header'
import CustomInput from '../components/CustomInput/CustomInput'
import Descriptions from '../components/Descriptions/Descriptions';
import { useEffect } from 'react';
function Page1() {
  useEffect(()=>{
    fetch('/ping/success')
    .then(res=>res.json())
    .then(data=>{
      console.log(data);
    })
    .catch(error=>{
      console.log(error);
    })
  }
  ,[])
  return (
    <div>
        <Header/>
        <CustomInput/>
        <Descriptions/>
    </div>
  )
}

export default Page1