import React, {useState,useEffect}from 'react'
import io from 'socket.io-client';
function Socketio() {
    const SOCKET_URL = window.location.origin.includes('localhost')
  ? 'http://localhost:5000' 
  : 'https://dev.solar-gps.com'; 
// const SOCKET_URL = 'http://localhost:5000'; 
    const [socket, setSocket] = useState(null);
    useEffect(() => {
      const socketIo = io(SOCKET_URL,{
        auth: {
            token : "quyet"
        }
      })
      socketIo.on('connect', () => {
        console.log('Connected to server');
      });
  
      socketIo.on('response', (message) => {
        console.log(`Received chat message: ${message}`);
      });
      setSocket(socketIo);
      return () => {
        socketIo.disconnect();
      };
    }, []);
  
    const sendMessage = () => {
      if (socket) {
        socket.emit('request', 'hello from client');
      }
    };
    return (
      <div>
        <h1>Socket.IO Client</h1>
        <button onClick={sendMessage}>Send</button>
      </div>
    );
  }

export default Socketio