import React, { useState } from 'react'
import { useEffect } from 'react'
function Login() {
  const [formData, setFormData] = useState({
    username : '',
    password : ''
  });
  const handleInputChange = (e)=>{
    const {name, value} = e.target;
    console.log(`name: ${name}, value: ${value}`);
    setFormData((prevData)=>(
      {
        ...prevData,
        [name] : value
      }
    ));
  };
  useEffect(()=>{
    fetch('/ping/login')
    .then(res=> res.json() )
    .then(data=>{
        console.log(data);
      }
    )
    .catch(error => {
      console.error('Error fetching /ping:', error);
    });
  },[])
  // const handleValidate = ()=>{
  //   console.log("valid")
  // } 
  const handleSubmit = (e)=>{
    e.preventDefault();
    console.log(formData);
    fetch('/api/auth/login',{
      method : "POST",
      headers : {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData)
    })
    .then(res => res.json())
    .then (data=>{
      console.log(data);
      if(data.message === "login successfully"){
        localStorage.setItem('accessToken', data.accessToken);
        localStorage.setItem('refreshToken', data.refreshToken);
        sessionStorage.setItem('testSession',"test");
      }
    })
    .catch((error)=>{
      console.log(error);
    })
  }
  const handleCheckToken = ()=>{
    const accessToken = sessionStorage.getItem('accessToken') || localStorage.getItem('accessToken');
    console.log("check token: "+accessToken);
    fetch('/api/auth/check-token',{
      method : "POST",
      headers : {
        "Content-Type": "application/json",
        'Authorization': `Bearer ${accessToken}`
      },
      body: JSON.stringify({'message':"do you see me?"})
    })
    .then(res => {
      if(res.status === 401){
        console.log("het han");
        handleRefreshToken();
      }else if(res.ok){
        return res.json();
      } else{
        console.log("failed");
      }
    })
    .then (data=>{
      console.log(data);
    })
    .catch((error)=>{
      console.log(error);
    })
  }
  const handleRefreshToken = ()=>{
    const refreshToken = sessionStorage.getItem('refreshToken') || localStorage.getItem('refreshToken');
    console.log("refresh: "+refreshToken);
    fetch('/api/auth/refresh-token',{
      method : "POST",
      headers : {
        "Content-Type": "application/json",
        'Authorization': `Bearer ${refreshToken}`
      },
    })
    .then(res => res.json())
    .then (data=>{
      console.log(data);
      if(data.message==="refresh successfully"){
        console.log("refresh successfully");
      }
    })
    .catch((error)=>{
      console.log(error);
    })
  }
  return (
    <div>
      <form onSubmit={handleSubmit}>
        <div>
          <label htmlFor='login-username-input'>USERNAME</label>
          <input 
            type="text" 
            placeholder='Username' 
            name="username" 
            id="login-username-input"
            value={formData.username}
            onChange={handleInputChange}
          ></input>
        </div>
        <div>
          <label htmlFor='login-password-input'>PASSWORD</label>
          <input 
            type="text" 
            placeholder='Password' 
            name="password" 
            id="login-password-input"
            value={formData.password}
            onChange={handleInputChange}
          ></input>
        </div>
        <div>
          <button type='submit'>Login</button>
        </div>
      </form>
      <button onClick={handleCheckToken}>
        Send
      </button>
    </div>
  )
}

export default Login