import React ,{ useRef, useEffect } from 'react';
import { motion } from 'framer-motion';
import './PopupDialog.css';
import { RxLapTimer } from "react-icons/rx";
import { IoCheckmarkDone } from "react-icons/io5";
import { FaXmark } from "react-icons/fa6";

function PopupDialog(props) {
  const modalRef = useRef(null);
  useEffect(() => {
    const handleClickOutside = (event) => {
      console.log('click');
      if (props.type !== 'expired-session' && modalRef.current && !modalRef.current.contains(event.target)) {
        props.onClose(); 
      }
    };
    document.addEventListener("mousedown", handleClickOutside); 
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [props]);
  const getPopupDialogData=(type)=>{
    switch(type){
      case "expired-session":
        return {
          icon:<RxLapTimer className='modal-icon'/>,
          title:"Your session has expired",
          content: "Please login to continue",
          buttonName: "Login",
          iconBG: '#9dc3eb',
          buttonBG: '#378CE7',
        };
      case "success":
        return {
          icon:<IoCheckmarkDone className='modal-icon'/>,
          title:"Success",
          content: "Apply change successfully",
          buttonName: "OK",
          iconBG: '#7be330',
          buttonBG: '#50bd02',
        };
      case "failed":
        return {
          icon:<FaXmark className='modal-icon'/>,
          title:"Something went wrong!",
          content: "Change could not be applied",
          buttonName: "OK",
          iconBG: '#e7684b',
          buttonBG: '#f03007',
        };
      default:
        return null;
    }
  }
  const modalData = getPopupDialogData(props.type);
  const motionVariants = {
    init: {opacity: 0, y:50},
    after: {opacity: 1, y: "-50%"},
    exit : {opacity: 0, y: -50}
  }
  return (
    <div className='modal-backdrop'>
      <motion.div className='modal-container' ref={modalRef}
        initial= "init"
        animate= "after"
        exit= "exit"
        variants= {motionVariants}
        transition = {{duration : 0.2}}
        style={{ position: 'absolute', top: '50%', left: '50%', x: '-50%', y: '-50%' }}
      >
          <div className='modal-icon-container' style={{backgroundColor : modalData.iconBG}}> 
            {modalData.icon} 
          </div>
          <div className='modal-title-container'>
              <h1 className='modal-title'>{modalData.title}</h1>
          </div>
          <div className='modal-content-container'>
            <p className='modal-content'>{modalData.content}</p>
          </div>
          <div className='modal-button-container'>
            <button className='modal-button' 
              style={{backgroundColor : modalData.buttonBG}}
              onClick={props.onClose}
            >
              {modalData.buttonName}
            </button>
          </div>
      </motion.div>
    </div>
  )
}

export default PopupDialog