import React from 'react';
import './Flash.css';
import { motion } from 'framer-motion' 
import { IoIosClose } from "react-icons/io";
import { BsEnvelopeX } from "react-icons/bs";
import { BsEnvelopeCheck } from "react-icons/bs";
import { BsEnvelopeAt } from "react-icons/bs";
import { BsEnvelopeExclamation } from "react-icons/bs";

function Flash(props) {
    const getFlashContent = (status) => {
        switch (status) {
            case 'success':
                return {color:"#3e9200",title:"Success!",icon:<BsEnvelopeCheck className='flash-result-icon'/>};
            case 'failed':
                return {color:"#f03007",title:"Failed!",icon:<BsEnvelopeX className='flash-result-icon'/>};
            case 'info':
                return {color:"#0078d6",title:"Info!",icon:<BsEnvelopeAt className='flash-result-icon'/>};
            case 'warning':
                return {color:"orange",title:"Warning!",icon:<BsEnvelopeExclamation className='flash-result-icon'/>};
            default:
                return null;
        }
    };
    const flashData = getFlashContent(props.flashData.flashStatus);
    const motionVariants = {
        hidden: { opacity: 0, x: 300 },  
        visible: { opacity: 1, x: 0 },  
        exit: { opacity: 0, x: 300 }   
    };
    return (
        <motion.div  className="flash-container" 
            initial="hidden"
            animate="visible"
            exit="exit"
            variants={motionVariants}
            transition={{ duration: 0.3 }}
        >
            <div className="flash-result-icon-container" style={{ backgroundColor : flashData.color }}>
                {flashData.icon}
            </div>
            <div className="flash-content-container">
                <h1 className="flash-title" style={{color:flashData.color}}> {flashData.title} </h1>
                <p className="flash-content">{props.flashData.flashContent}</p>
            </div>
            <div className="flash-close-icon-container" onClick={props.onClose}>
                <IoIosClose className='flash-close-icon' style={{color:flashData.color}}/>
            </div>
        </motion.div>
    )
}

export default Flash