import React, {useState}from 'react';
import Flash from '../components/Flash/Flash';
import PopupDialog from '../components/PopupDialog/PopupDialog';
import { AnimatePresence } from 'framer-motion';

function FlashPage() {
    const [display, setDisplay] =useState('hide');
    const [showDialog, setShowDialog] =useState('hide');
    const [dialogType, setDialogType] =useState('success');
    function handleShow(){
        setDisplay('show');
    }
    function handleHide(){
        setDisplay('hide');
    }
    function handleShowDialog(type){
        setDialogType(type);
        setShowDialog('show');
    }
    function handleHideDialog(){
        setShowDialog('hide');
    }
    const flashData={
        flashStatus : "success",
        flashContent : "This is flash test content not a real flash content",
    }
  return (
    <div>
        <AnimatePresence>
            {display === "show" && <Flash flashData={flashData} onClose={handleHide}/>}
        </AnimatePresence>
        <AnimatePresence>
            {showDialog === "show" && <PopupDialog type={dialogType} onClose={handleHideDialog}/>}
        </AnimatePresence>

        <button onClick={handleShow}>Flash</button> 
        <button onClick={handleHide}>Hide</button>
        <button onClick={()=>handleShowDialog('success')}>Success</button>
        <button onClick={()=>handleShowDialog('failed')}>Failed</button>
        <button onClick={()=>handleShowDialog('expired-session')}>Session</button>  
        <button onClick={handleHideDialog}>Unpop</button> 
    </div>
  )
}

export default FlashPage