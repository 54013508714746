import './App.css';
import Page1 from './pages/Page1';
import Login from './pages/Login';
import Other from './pages/Other';
import FlashPage from './pages/FlashPage';
import Socketio from './pages/Socketio';
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';
function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/login" element = {<Login/>}/>
          <Route path="/success" element={<Page1/>}/>
          <Route path="/flash" element={<FlashPage/>}/>
          <Route path="/socket" element={<Socketio/>}/>
          <Route path="*" element={<Other/>}/>
        </Routes>
      </Router>
      
    </div>
  );
}

export default App;
