import React, {useState} from 'react'
import {faXmark} from '@fortawesome/free-solid-svg-icons'
import './ListItem.css'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
function ListItem(props) {
  const [isHover, setIsHover] = useState(false);
  function handleMouseOver(){
    setIsHover(true);
  }
  function handleMouseOut(){
    setIsHover(false);
  }
  function handleRemove(){
    props.onRemove();
  }
  function handleChecked(event) {
    props.onChange(event.target.checked);
  }
  return (
    <div className='to-do-item' onMouseOver={handleMouseOver} onMouseOut={handleMouseOut}>
      <div className='to-do-display'>
        <input type='checkbox' 
            name="to-do-checkbox" 
            id={`checkbox-${props.id}`} 
            checked={props.checked}
            onChange={handleChecked}/>
        <label
            className='to-do-item-label'
            htmlFor={`checkbox-${props.id}`}
        >{props.content}</label>
      </div>
      {(isHover)&&(
        <div className='to-do-edit'>
          <FontAwesomeIcon icon={faXmark} onClick={handleRemove}/>
        </div>
      )}
    </div>
  )
}

export default ListItem