import React,{useState} from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import ListItem from '../ListItem/ListItem';
import Filter from '../Filter/Filter';
import './CustomInput.css'
function CustomInput() {
    const [content, setContent] = useState([]);
    const [currContent, setCurrContent] = useState('');
    const [checkedItems, setCheckedItems] = useState({});
    const [activeButton,setActiveButton] = useState('all');
    function handleKeyDown(event){
        if (event.key === 'Enter') {
            setContent(prev=>{
                return [...prev,currContent]
            });
            setCurrContent('');
        }
    }
    function handleChange(event){
        console.log(currContent);
        setCurrContent(event.target.value); 
    }
    function handleRemove(index){
        setContent(prevItems => prevItems.filter((_, i) => i !== index));
        setCheckedItems(prev => {
            const newCheckedItems = { ...prev };
            delete newCheckedItems[index];
            return newCheckedItems;
          });
    }
    function handleChecked(index, checked) {
        setCheckedItems(prev => {
          const newCheckedItems = { ...prev };
          if (checked) {
            newCheckedItems[index] = checked;
          } else {
            delete newCheckedItems[index];
          }
          return newCheckedItems;
        });
    }
    function handleButton(button){
        setActiveButton(button); 
    }
  return (
    <div className='custom-input'>
        <div className='input-field'>
            <div className='icon-container'>
                <FontAwesomeIcon icon={faChevronDown} />
            </div>
            <input 
                className='main-input' 
                type='text' 
                value={currContent}
                placeholder='What do you want to do today'
                onChange={handleChange}
                onKeyDown={handleKeyDown}
            />
        </div>
        {(content.length > 0) && (
                <div className='list-item-container'>
                    {
                        content.map((text,index)=>(
                            <ListItem key={index} 
                                id={index} 
                                content={text} 
                                onRemove={()=>handleRemove(index)}
                                onChange={(checked) => handleChecked(index, checked)}
                                checked={!!checkedItems[index]}
                            />
                        ))
                    }
                </div>
            )
        }
        <Filter number={content.length- Object.entries(checkedItems).length} 
            active={activeButton}
            onActive={(button)=>handleButton(button)}/>
    </div>
  )
}

export default CustomInput