import React from 'react'
import './Filter.css'
function Filter(props) {
  return (
    <div className='filter-block'>
      <div>
        <p>{props.number} Items left</p>
      </div>
      <div className='button-group'>
        <button className={(props.active==="all")?('button-active'):''} onClick={()=>props.onActive("all")}>All</button>
        <button className={(props.active==="active")?('button-active'):''} onClick={()=>props.onActive("active")}>Active</button>
        <button className={(props.active==="completed")?('button-active'):''} onClick={()=>props.onActive("completed")}>Completed</button>
        <button className={(props.active==="clear")?('button-active'):''} onClick={()=>props.onActive("clear")}>Clear Completed</button>
      </div>
    </div>
  )
}

export default Filter