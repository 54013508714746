import React from 'react'
import "./Descriptions.css"
function Descriptions() {
  return (
    <p className='descriptions'>This page is completely accessible. If you want to use your keyboard, go ahead and try it!

    You can also edit todo item by pressing space key in your keyboard while focusing to todo label.
    
    If you want to filter your todo items, no problem. Just use the filter buttons
    
    To remove an item press delete key in your keyboard when you focus to TODO item.
    
    You can also delete todo by hovering the item and then press the delete button.</p>
  )
}

export default Descriptions