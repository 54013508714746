import React from 'react'
import './Header.css'
function Header() {
  return (
    <div className='header-content'>
        Today's TODO's
    </div>
  )
}

export default Header
